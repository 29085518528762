@charset "utf-8";


@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

.grid__item{

    .archive__item-teaser {
        @include breakpoint($small) {
          max-height: 200px;
        }
    
        @include breakpoint($medium) {
          max-height: 220px;
          max-width: 220px;
          position: relative;
          overflow:hidden;
        }
      }
    }

    @mixin scut-link-unstyled {
      &, &:visited, &:hover, &:active {
        font-style: inherit;
        color: inherit;
        background-color: transparent;
        font-size: inherit;
        text-decoration: none;
        font-variant: inherit;
        font-weight: inherit;
        line-height: inherit;
        font-family: inherit;
      }
    }
    
    %scut-link-unstyled {
      @include scut-link-unstyled;
    }
    
    .unstyled {
      @extend %scut-link-unstyled;
    }

    .categoryListing {
      margin-top: -0.75em;
      color: $muted-text-color;
      font-family: $sans-serif;
      font-size: $type-size-6;
    
      p {
        margin: 0;
      }
    
      a {
        color: inherit;
      }
    }
.recipetext {
  vertical-align:top; 
  width: 100%; 
  line-height: 1; 
  font-size: 0.8em;
}

.recipestats {
  display: inline-block; 
  width: 23%;
  vertical-align: top;
}